import React, { useState, useEffect } from 'react';
import {
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tab,
    Tabs, ButtonGroup, Tooltip, Typography, DialogContent, Dialog
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import ExchangeService from '../../services/exchange.service';
import Table from '@mui/material/Table';
import { Link, useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";
import VOCSETLayout, {countryCodeToFlag} from '../../views/VOCSETLayout';
import { dropdownItems } from '../../views/dropdownItems';
import { MoonLoader } from "react-spinners";
import {Exchange} from "../../types/APITypes";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";


createTheme();

const ExchangeDashboard = () => {
    const [exchanges, setExchanges] = useState<Exchange[]>([]);
    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [selectedOwner, setSelectedOwner] = useState<string>('');
    const [privileges, setPrivileges] = useState<string[]>([]);
    const [action, setAction] = useState<'activate' | 'delete' | 'error' | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();

    const resize = () => {
        setOpen(window.innerWidth >= 760);
    };

    useEffect(() => {
        // @ts-ignore
        window.addEventListener('resize', resize.bind(this));
        resize();
    }, []);

    useEffect(() => {
        const storedPrivileges = localStorage.getObj('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                )
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                )
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        })
    }, [])

    useEffect(() => {
        const fetchExchanges = async () => {
            try {
                const response = await ExchangeService.getExchanges();
                if (response) {
                    setLoading(false)
                }
                const fetchedExchanges = response.result;
                if (fetchedExchanges && fetchedExchanges.length > 0) {
                    setExchanges(fetchedExchanges);
                    setSelectedOwner(fetchedExchanges[0].ownerName)
                } else {
                    setError('No exchanges available.');
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching exchanges:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching exchanges. Please try again.');
            }
        };

        fetchExchanges();
    }, []);

    const uniqueOwners = Array.from(new Set(exchanges.map(exchange => exchange.ownerName)));
    const filteredExchanges = exchanges.filter(exchange => exchange.ownerName === selectedOwner);

    const handleRowClick = (mic: string) => {
        navigate(`/ProductDashboard`, { state: { selectedMic: mic } });
    };

    let dialogContent = '';
    if (action === 'activate') {
        dialogContent = 'Activation complete';
    } else if (action === 'delete') {
        dialogContent = 'Deactivation complete';
    } else if (action === 'error') {
        dialogContent = error;
    }

    const handleEditClick = (event: { stopPropagation: () => void; }, exchange: any) => {
        event.stopPropagation();
        navigate(`/EditExchange`, { state: exchange });
    };

    const handleActivationClick = (event: { stopPropagation: () => void; }, exchange: any) => {
        event.stopPropagation();
        handleActivation(exchange)
    };

    const handleDeleteClick = (event: { stopPropagation: () => void; }, mic: any) => {
        event.stopPropagation();
        handleDelete(mic)
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleActivation = async (exchange: Exchange) => {
        try {
            const updatedExchangeData = {
                ...exchange,
                active: true,
            };

            await ExchangeService.updateExchange(updatedExchangeData);

            setAction('activate');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);

        } catch (error: any) {
            console.error('Error activating exchange:', error);
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error activating exchange. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    const handleDelete = async (mic: string) => {
        try {
            await ExchangeService.deleteExchange(mic);
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error deleting exchange. Please try again.');
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                {privileges.includes('CREATE_ANY_EXCHANGE') && (
                    <ButtonGroup>
                        <Button aria-label="New Exchange" variant="contained" component={Link} to="/CreateExchange" startIcon={<AddIcon />}>New Exchange</Button>
                    </ButtonGroup>
                )}

                <Tabs
                    value={selectedOwner}
                    onChange={(event, newValue) => setSelectedOwner(newValue)}
                    aria-label="Owner Tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {uniqueOwners.map(owner => (
                        <Tab key={owner} label={owner} value={owner} />
                    ))}
                </Tabs>
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    filteredExchanges.length === 0 ? (
                        <p>No exchanges found for selected owner.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Country</TableCell>
                                        {privileges.includes('WRITE_ANY_EXCHANGE') && (
                                            <TableCell>Actions</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredExchanges.map((exchange) => (
                                        <TableRow key={exchange.id}
                                                  className="clickable-row"
                                                  style={{backgroundColor: exchange.active ? '' : '#f5f5f5'}}
                                                  onClick={() => handleRowClick(exchange.mic)}>
                                            <TableCell>{exchange.mic}</TableCell>
                                            <TableCell>{exchange.name}</TableCell>
                                            <TableCell>
                                                <Tooltip title={exchange.countryCode} arrow>
                                                    <Typography variant="h6" color="inherit" noWrap>
                                                        {countryCodeToFlag(exchange.countryCode)}
                                                    </Typography>
                                                </Tooltip>
                                            </TableCell>
                                            {privileges.includes('WRITE_ANY_EXCHANGE') && (
                                                <TableCell>
                                                    <ButtonGroup className="dash-button-group">
                                                        <Link to={`/EditExchange`} state={exchange} className="clt-row"
                                                              style={{textDecoration: 'none', color: 'inherit'}}>
                                                            <Button className="dash-button" onClick={(event) =>
                                                                handleEditClick(event, exchange)} startIcon={<EditIcon/>}/>
                                                        </Link>
                                                        {exchange.active ? (
                                                            <Button
                                                                className="dash-button"
                                                                startIcon={<DeleteIcon/>}
                                                                onClick={(event) => handleDeleteClick(event, exchange.mic)}
                                                            />
                                                        ) : (
                                                            <Button
                                                                className="dash-button"
                                                                startIcon={<DoneIcon/>}
                                                                onClick={(event) => handleActivationClick(event, exchange)}
                                                            >
                                                            </Button>
                                                        )}
                                                    </ButtonGroup>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ExchangeDashboard;
