import React, { useState, useEffect } from 'react';
import { Box, Button, Container, CssBaseline, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../services/auth.service';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {jwtDecode} from "jwt-decode";
import {isNullOrUndefined} from "node:util";

const defaultTheme = createTheme();

const Authenticate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [totpCode, setTotpCode] = useState('');
    const [qrCodeImg, setQRCodeImg] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const { username, password, qrCodeImg } = location.state;
        setUsername(username);
        setPassword(password);
        setQRCodeImg(qrCodeImg ? "data:image/jpg;base64,"+qrCodeImg : "");
    }, [location.state]);

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        try {
            const response = await AuthService.login(username, password, totpCode);
            console.log('auth res ', response)
            if (response && response.status === "OK") {
                const storedPrivileges: any[] = localStorage.getObj('privileges');
                setError('');
                if (storedPrivileges.includes("ROLE_NONE")) {
                    navigate('/UserSetupNotification', { state: { username, password } });
                } else {
                    navigate('/TradeDashboard', { state: { username, password } });
                }
            } else {
                console.error('Status received: ', response.status);
                setError('Incorrect OTP. Please try again.');
            }
        } catch (error) {
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error verifying OTP. Please try again.');
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
                        OTP Code Authentication
                    </Typography>
                    {error && (
                        <Typography color="error" variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
                            {error}
                        </Typography>
                    )}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, textAlign: 'center' }}>
                        {qrCodeImg && <img src={qrCodeImg} alt="QR Code" style={{ maxWidth: '100%' }} />}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="totpCode"
                            label="Verify OTP Code"
                            type="text"
                            id="totpCode"
                            value={totpCode}
                            onChange={(e) => setTotpCode(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Authenticate
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Authenticate;
