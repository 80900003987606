import React, {useState, useEffect} from 'react';
import {
    ButtonGroup, Dialog, DialogContent, Tab,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tabs, Typography
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import UserService from '../../services/user.service';
import Table from '@mui/material/Table';
import {Link, useNavigate} from 'react-router-dom';
import Button from "@mui/material/Button";
import VOCSETLayout from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import {MoonLoader} from "react-spinners";
import {User} from "../../types/APITypes";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import LockResetIcon from '@mui/icons-material/LockReset';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AuthService from "../../services/auth.service";

createTheme();
const UserDashboard = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState<User[]>([]);
    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [privileges, setPrivileges] = useState<string[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState<'activate' | 'delete' | 'error' | 'passwordReset' | 'otpReset' | null>(null);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [selectedOwner, setSelectedOwner] = useState<string>('');
    const [uniqueOwners, setUniqueOwners] = useState<string[]>([]);
    const [requestUsername, setRequestedUsername] = useState<User | null>(null);

    const resize = () => {
        setOpen(window.innerWidth >= 760);
    };
    useEffect(() => {
        // @ts-ignore
        window.addEventListener('resize', resize.bind(this));
        resize();
    }, []);

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                )
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                )
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        })
    }, [])

    useEffect(() => {
        const storedPrivileges = localStorage.getObj('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges);
        }
        const fetchUsers = async () => {
            try {
                const response = await UserService.getUsers();
                if (response) {
                    setLoading(false)
                }
                const fetchedUsers = response.result;
                if (fetchedUsers && fetchedUsers.length > 0) {
                    setUsers(fetchedUsers);
                    const owners = fetchedUsers.map((user: { ownerShortName: string }) => user.ownerShortName);
                    const uniqueOwners = owners.filter((owner: any, index: any) => owners.indexOf(owner) === index);
                    setUniqueOwners(uniqueOwners);
                    if (uniqueOwners.length > 0) {
                        setSelectedOwner(uniqueOwners[0]);
                    }
                } else {
                    setError('No users available.');
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching users:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching users. Please try again.');
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const userProfile = JSON.parse(localStorage.getItem('userProfile') || '{}');
        const roles = userProfile?.roles?.map((role: { name: string }) => role.name) || [];
        setUserRoles(roles);
    }, []);

    let dialogContent = '';
    if (action === 'activate') {
        dialogContent = 'Activation complete';
    } else if (action === 'delete') {
        dialogContent = 'Deactivation complete';
    } else if (action === 'error') {
        dialogContent = error;
    } else if (action === 'passwordReset' && requestUsername) {
        dialogContent = `Password reset email sent to ${requestUsername.username}`;
    } else if (action === 'otpReset' && requestUsername) {
        dialogContent = `OTP reset email sent to ${requestUsername.username}`;
    }


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleActivation = async (user: User) => {
        try {

            const updatedUserData = {
                ...user,
                active: true,
            };

            await UserService.updateUser(updatedUserData);
            setAction('activate');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error: any) {
            console.error('Error activating user:', error);
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error activating user. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    const handlePasswordReset = async (user: User) => {
        try {
            await AuthService.resetPassword(user.username);
            setAction('passwordReset');
            setRequestedUsername(user)
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error: any) {
            console.error('Error resetting user password:', error);
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error generating password reset request. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    const handleOTPReset = async (user: User) => {
        try {
            await AuthService.resetOTP(user.username);
            setAction('otpReset');
            setRequestedUsername(user)
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error: any) {
            console.error('Error resetting user password:', error);
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error generating password reset request. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };


    const handleDelete = async (username: string) => {
        try {
            await UserService.deleteUser(username);
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error: any) {
            console.error('Error deleting user:', error);
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error deleting user. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    const renderUserRows = (filteredUsers: any[]) => {
        return filteredUsers.map((user) => {
            const loggedInUser = JSON.parse(localStorage.getItem('userProfile') || '{}');
            const isCurrentUser = loggedInUser?.username === user.username;

            return (
                <TableRow key={user.id} style={{backgroundColor: user.active ? '' : '#f5f5f5'}}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.roles.length > 0 ? user.roles[0].name : ''}</TableCell>
                    {(privileges.includes('WRITE_ANY_USER') || privileges.includes('WRITE_OWN_USER')) && (
                        <TableCell>
                            <ButtonGroup className="dash-button-group">
                                <Link to={`/EditUser`} state={user}>
                                    <Button className="dash-button" startIcon={<EditIcon/>}/>
                                </Link>
                                {user.active ? (
                                    <Button className="dash-button" startIcon={<DeleteIcon/>}
                                            onClick={() => handleDelete(user.username)}/>
                                ) : (
                                    <Button className="dash-button" startIcon={<DoneIcon/>}
                                            onClick={() => handleActivation(user)}/>
                                )}
                                {!isCurrentUser && (userRoles.includes('ADMIN') || userRoles.includes('SUPERUSER')) && (
                                    <>
                                        <Button className="dash-button" startIcon={<LockResetIcon/>}
                                                onClick={() => handlePasswordReset(user)}/>
                                        <Button className="dash-button" startIcon={<QrCodeIcon/>}
                                                onClick={() => handleOTPReset(user)}/>
                                    </>
                                )}
                            </ButtonGroup>
                        </TableCell>
                    )}
                </TableRow>
            );
        });
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                {(privileges.includes('CREATE_ANY_USER') || privileges.includes('CREATE_OWN_USER')) && (
                    <ButtonGroup>
                        <Button aria-label="New User Button" variant="contained" component={Link} to="/CreateUser"
                                startIcon={<AddIcon/>}>New User</Button>
                    </ButtonGroup>
                )}
                {userRoles.includes('SUPERUSER') && (
                    <Tabs value={selectedOwner} onChange={(event, newValue) =>
                        setSelectedOwner(newValue)} aria-label="Users by Owner"
                          variant="scrollable"
                          scrollButtons="auto">
                        {uniqueOwners.map(owner => {
                            const ownerLabel = owner ? owner : 'NEW';
                            return <Tab key={ownerLabel} label={ownerLabel} value={ownerLabel}/>;
                        })}
                    </Tabs>
                )}
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : users.length === 0 ? (
                    <p>No users found.</p>
                ) : (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Username</TableCell>
                                    <TableCell>First Name</TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Roles</TableCell>
                                    {(privileges.includes('WRITE_ANY_USER') || privileges.includes('WRITE_OWN_USER')) &&
                                        <TableCell>Actions</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userRoles.includes('SUPERUSER')
                                    ? renderUserRows(users.filter(user => user.ownerShortName === selectedOwner || (selectedOwner === 'NEW' && !user.ownerShortName)))
                                    : renderUserRows(users)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default UserDashboard;