import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    Grid,
    MenuItem,
    Select
} from '@mui/material';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import VOCSETLayout from '../../views/VOCSETLayout';
import NotificationService from '../../services/notification.service';
import {dropdownItems} from "../../views/dropdownItems";
import CompanyService from "../../services/company.service";
import AccountService from "../../services/account.service";
import {Account, Company} from "../../types/APITypes";

interface EditNotificationProps {
    onSuccess: () => void;
}

const EditNotification: React.FC<EditNotificationProps> = ({onSuccess}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const notificationData = location.state;
    const [action, setAction] = useState<'save' | null>(null);


    const [notificationState, setNotificationState] = useState({
        id: notificationData?.id || '',
        client: notificationData?.client || '',
        clearingAccount: notificationData?.clearingAccount || '',
        teamName: notificationData?.teamName || '',
        channelName: notificationData?.channelName || ''
    });

    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [accounts, setAccounts] = useState<Account[]>([]);

    useEffect(() => {
        async function fetchCompanies() {
            try {
                console.log('state ', location.state)
                const response = await CompanyService.getCompanies();
                const clientCompanies = response.result.filter((company: Company) =>
                    company.roles.some(role => role.name === 'CLIENT') && company.active
                );
                setCompanies(clientCompanies);
            } catch (error) {
                console.error('Error fetching companies:', error);
                setError('Error fetching companies.');
            }
        }

        fetchCompanies();
    }, []);

    useEffect(() => {
        async function fetchAccounts() {
            try {
                const response = await AccountService.getAccounts();
                const filteredAccounts = response.result.filter((acc: { client: string; }) => acc.client === notificationState.client);
                setAccounts(filteredAccounts);
            } catch (error) {
                console.error('Error fetching accounts:', error);
                setError('Error fetching accounts.');
            }
        }

        fetchAccounts();
    }, []);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/NotificationDashboard');
    };

    const handleUpdate = async () => {
        try {
            await NotificationService.updateNotification(notificationState);
            onSuccess();
            setAction('save');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error updating notification:', error);
            setError('Error updating notification. Please try again.');
        }
    };

    let dialogContent = '';
    if (action === 'save') {
        dialogContent = 'Update complete';
    }

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box sx={{marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Typography component="h1" variant="h5">Edit Notification</Typography>
                    <Box component="form" noValidate sx={{mt: 3}}>
                        <Grid container spacing={2} sx={{paddingBottom: 4}}>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    required
                                    value={notificationState.client}
                                    onChange={(e) => setNotificationState({
                                        ...notificationState,
                                        client: e.target.value
                                    })}
                                    displayEmpty
                                    sx={{marginTop: '16px'}}
                                >
                                    {companies.map(company => (
                                        <MenuItem key={company.id}
                                                  value={company.shortName}>{company.shortName}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    required
                                    value={notificationState.clearingAccount}
                                    onChange={(e) => setNotificationState({
                                        ...notificationState,
                                        clearingAccount: e.target.value
                                    })}
                                    displayEmpty
                                    sx={{marginTop: '16px'}}
                                >
                                    {accounts.map(acc => (
                                        <MenuItem key={acc.id} value={acc.name}>{acc.name}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Team Name"
                                    value={notificationState.teamName}
                                    onChange={(e) => setNotificationState({
                                        ...notificationState,
                                        teamName: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Channel Name"
                                    value={notificationState.channelName}
                                    onChange={(e) => setNotificationState({
                                        ...notificationState,
                                        channelName: e.target.value
                                    })}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={handleUpdate}
                                variant="contained"
                                sx={{width: 'auto', mb: 2}}
                                disabled={!notificationState.client || !notificationState.clearingAccount || !notificationState.teamName || !notificationState.channelName}
                            >
                                Update
                            </Button>
                            <Button
                                component={Link}
                                to="/NotificationDashboard"
                                variant="contained"
                                color="primary"
                                sx={{width: 'auto', mb: 2, marginLeft: 3}}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        {error && (
                            <Typography variant="body2" color="error">
                                {error}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EditNotification;
