import React, {useEffect, useState} from 'react';
import {
    Box, Button, CssBaseline, TextField, Typography, Dialog,
    DialogContent, Select, MenuItem, Grid
} from '@mui/material';
import {Link, useNavigate} from "react-router-dom";
import {dropdownItems} from "../../views/dropdownItems";
import VOCSETLayout from '../../views/VOCSETLayout';
import SubAccountService from "../../services/subAccount.service";
import AssociationService from '../../services/association.service';
import AccountService from "../../services/account.service";

function CreateSubAccount() {
    const [client, setClient] = useState('');
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [externalCode, setExternalCode] = useState('');
    const [active, setActive] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [clientOptions, setClientOptions] = useState<{ client: string }[]>([]);
    const [selectedClient, setSelectedClient] = useState<{ client: string } | null>(null);
    const navigate = useNavigate();

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/SubAccountDashboard')
    };

    useEffect(() => {
        const fetchAssociationsAndAccounts = async () => {
            try {
                const associations = await AssociationService.getAssociations();
                const associateNames = new Set(
                    associations.filter((assoc: { associationTypeName: string }) => assoc.associationTypeName === 'allocatesTo')
                        .map((assoc: { principalShortName: string }) => assoc.principalShortName)
                );
                const accounts = await AccountService.getAccounts();
                const uniqueClients = Array.from(
                    new Set(
                        accounts.result
                            .filter((account: { name: string }) => associateNames.has(account.name))
                            .map((account: { client: string }) => account.client)
                    )
                );
                setClientOptions(uniqueClients.map(client => ({ client: client as string })));
            } catch (error) {
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching client options. Please try again.');
            }
        };
        fetchAssociationsAndAccounts();
    }, []);



    const handleSubmit = async (event: {
        preventDefault: () => void;
    }) => {
        event.preventDefault();

        console.log('client ', client)

        const role = {
            id: 'ROLE_SUB_ACCOUNT',
            name: 'SUB_ACCOUNT'
        };

        if (!selectedClient) {
            setError('Please select a client');
            return;
        }

        try {
            const subAccountData = {
                client: selectedClient?.client,
                code,
                externalCode,
                name,
                active,
                role
            };
            console.log(subAccountData)

            await SubAccountService.createSubAccount(subAccountData);
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error creating sub account. Please try again.');
        }
    };

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Create Sub Account
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3, width: '100%'}}>
                        <Grid container spacing={2} sx={{paddingBottom: 4}}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="code"
                                    label="Code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="externalCode"
                                    label="External Code"
                                    value={externalCode}
                                    onChange={(e) => setExternalCode(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    id="client"
                                    value={selectedClient?.client || ''}
                                    onChange={(e) => {
                                        const selected = clientOptions.find(option => option.client === e.target.value);
                                        setSelectedClient(selected || null);}}
                                    renderValue={(selected) => (selected ? selected : 'Select Client')}
                                    displayEmpty
                                    sx={{ marginTop: '16px' }}
                                    disabled={!clientOptions.length}
                                >
                                    {Array.from(new Set(clientOptions.map(option => option.client)))
                                        .map((client, index) => (
                                            <MenuItem key={index} value={client}>
                                                {client}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{width: 'auto', mb: 2}}
                                    disabled={clientOptions.length === 0 || !selectedClient}
                                >
                                    Save
                                </Button>
                                <Button
                                    component={Link}
                                    to="/SubAccountDashboard"
                                    variant="contained"
                                    color="primary"
                                    sx={{width: 'auto', mb: 2, marginLeft: 3}}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{alignSelf: 'flex-start'}}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">Sub Account created successfully!</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CreateSubAccount;