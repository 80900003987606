import {Company, Trade, TradeStatistics} from "../../../types/APITypes";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const calculateTotalTrades = (trades: TradeStatistics[]) => {
    if (trades.length === 0) {
        return 0;
    }

    let tradeCount = 0;

    trades.forEach(trade => {
        tradeCount += trade.tradeCount
    });
    return tradeCount;
};

export const calculateTotalContracts = (trades: TradeStatistics[]) => {
    return trades.reduce((sum, trade) => sum + trade.tradeVolume, 0);
};

export const calculateStats = (trades: TradeStatistics[], history: number) => {
    const now = dayjs();
    const historyStart = now.subtract(history, 'day');
    return trades.filter(trade => {
        const tradeDate = dayjs(trade.tradeDate).tz('UTC');
        return tradeDate.isAfter(historyStart) && tradeDate.isBefore(now);
    });
};

export const calculateTotalBrokers = (trades: TradeStatistics[], companies: Company[]) => {
    const activeBrokers = new Set(companies.map(company => company.shortName));

    const brokerTradeCount: { [key: string]: number } = {};

    trades.forEach(trade => {
        if (activeBrokers.has(trade.shortName)) {
            brokerTradeCount[trade.shortName] = (brokerTradeCount[trade.shortName] || 0) + 1;
        }
    });

    return Object.keys(brokerTradeCount).length;
};

export const calculateCompliantTradePercentage = (trades: TradeStatistics[]) => {
    if (trades.length === 0) {
        return 0;
    }

    let tradeVolume = 0;
    let compliantTradeCount = 0;

    trades.forEach(trade => {
        tradeVolume += trade.tradeVolume
        compliantTradeCount += trade.compliantTradeCount
    });

    return Math.round((compliantTradeCount / tradeVolume) * 100);
};
