import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogContent
} from '@mui/material';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {dropdownItems} from '../../views/dropdownItems';
import VOCSETLayout from "../../views/VOCSETLayout";
import {Grid} from '@mui/material';
import SubAccountService from "../../services/subAccount.service";

interface EditSubAccountProps {
    onSuccess: () => void;
}

const EditSubAccount: React.FC<EditSubAccountProps> = ({onSuccess}) => {
    const [id, setId] = useState('');
    const [client, setClient] = useState('');
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [externalCode, setExternalCode] = useState('');
    const [active, setActive] = useState(true);
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const location = useLocation();
    const [action, setAction] = useState<'save' | null>(null);
    const navigate = useNavigate();
    const subAccount = location.state || {}

    useEffect(() => {
        setId(subAccount.id || '');
        setClient(subAccount.client || '');
        setCode(subAccount.code || '');
        setName(subAccount.name || '');
        setExternalCode(subAccount.externalCode || '');
        setActive(subAccount.hasOwnProperty('active') ? subAccount.active : true);
    }, [subAccount]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/SubAccountDashboard')
    };

    const handleSave = async (actionType?: 'activate' | 'save') => {
        try {
            const updatedSubAccountData = {
                id,
                client,
                code,
                externalCode,
                name,
                active: actionType === 'activate' ? true : active
            };
            await SubAccountService.updateSubAccount(updatedSubAccountData);
            onSuccess();
            setAction('save');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error updating sub account:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error updating sub account. Please try again.');
        }
    };

    let dialogContent = '';
    if (action === 'save') {
        dialogContent = 'Update complete';
    }

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%'
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                        Edit Sub Account
                    </Typography>
                    <Box component="form" noValidate sx={{mt: 3, width: '100%'}}>
                        <Grid container spacing={2} sx={{paddingBottom: 4}}>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="code"
                                    label="Code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="externalCode"
                                    label="External Code"
                                    value={externalCode}
                                    onChange={(e) => setExternalCode(e.target.value)}
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="client"
                                    label="Client"
                                    value={client}
                                    onChange={(e) => setClient(e.target.value)}
                                    disabled
                                    sx={{marginTop: '16px'}}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => handleSave('save')}
                                    variant="contained"
                                    sx={{width: 'auto', mb: 2}}
                                >
                                    Save
                                </Button>
                                <Button
                                    component={Link}
                                    to="/SubAccountDashboard"
                                    variant="contained"
                                    color="primary"
                                    sx={{width: 'auto', mb: 2, marginLeft: 3}}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {error && (
                        <Typography variant="body2" color="error" sx={{alignSelf: 'flex-start'}}>
                            {error}
                        </Typography>
                    )}
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default EditSubAccount;