import axios from "axios";
import {jwtDecode, JwtPayload} from "jwt-decode";
import {handleRequestError} from '../errorHandler';
import {array} from "yup";
import authHeader from "./auth.header";

const AUTH_URL = "/api/auth";
const ctype = {'Content-Type': 'application/json'}

class AuthService {
    login(username: string, password: string, totpCode: string) {
        return axios
            .post(AUTH_URL, {
                username,
                password,
                totpCode
            })
            .then(response => {
                if (response.data.result) {
                    if (response.data.result[0].id === "2FRQ") {
                        console.log("2FA Required")
                    } else if (response.data.result[0].id === "AUTH") {
                        localStorage.setItem("token", response.data.result[0].message);
                        const decodedToken = jwtDecode(response.data.result[0].message);
                        console.log(decodedToken);
                        localStorage.setItem("currentUser", decodedToken.sub as string)
                        // @ts-ignore
                        localStorage.setObj("privileges", decodedToken["privileges"])
                    }
                }
                return response.data.result[0];
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    resetPassword(username: string): Promise<string | boolean> {
        return axios
            .post(`${AUTH_URL}/resetPassword`, {username})
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    savePassword(code: string, newPassword: string): Promise<string | boolean> {
        return axios
            .post(`${AUTH_URL}/savePassword`, {code, newPassword})
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    }

    resetOTP(username: string): Promise<string | boolean> {
        return axios
            .post(`${AUTH_URL}/resetOTP`, null, {
                params: { username: username },
                headers: { ...authHeader(), ...ctype }
            })
            .then(response => response.data)
            .catch(error => {
                console.error(error);
                throw error;
            });
    }
}

export default new AuthService();
