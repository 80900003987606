import {handleRequestError} from '../errorHandler';
import authHeader from "./auth.header";
import axiosCache from '../components/cache/axiosCache'
import axios from "axios";
import {Role} from "../types/APITypes";

const SDM_SERVICE_URL = '/api/sdm';
const ctype = {'Content-Type': 'application/json'}

class SubAccountService {
    async getSubAccounts() {
        try {
            const response = await axiosCache.get(`${SDM_SERVICE_URL}/subAccount`,
                {
                    headers: {...authHeader(), ...ctype},
                    params: {isActive: false},
                    id: 'sub-account'
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async createSubAccount(subAccountData: {
        client: string;
        code: string;
        externalCode: string;
        name: string;
        active: boolean;
        role: Role;
    }) {
        try {
            const response = await axios.post(`${SDM_SERVICE_URL}/subAccount`, subAccountData,
                {
                    headers: {...authHeader(), ...ctype}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async updateSubAccount(subAccountData: {
        id: string;
        client: string;
        code: string;
        externalCode: string;
        name: string;
        active: boolean;
    }) {
        try {
            const response = await axios.post(`${SDM_SERVICE_URL}/subAccount/update`, subAccountData,
                {
                    headers: {...authHeader(), ...ctype}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async deleteSubAccount(code: string, client: string) {
        try {
            const response = await axios.delete(`${SDM_SERVICE_URL}/subAccount`,
                {
                    headers: {...authHeader(), ...ctype},
                    params: {code: code, client: client}
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

}

export default new SubAccountService();
