import axios from 'axios';
import {handleRequestError} from '../errorHandler';
import authHeader from "./auth.header";
import axiosCache from '../components/cache/axiosCache'

const ALIAS_SERVICE_URL = '/api/alias';
const ctype = {'Content-Type': 'application/json'}

class AliasService {
    async findAliases() {
        try {
            const response = await axiosCache.get(`${ALIAS_SERVICE_URL}`, {
                headers: {...authHeader(), ...ctype}, id: 'alias'
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async findAliasValues(entityName: string, accountRole?: string, client?: string) {
        try {
            const response = await axios.post(`${ALIAS_SERVICE_URL}/aliasValues`, entityName, {
                headers: {...authHeader(), 'Content-Type': 'text/plain'},
                params: {accountRole, client}
            });
            return response.data.result;
        } catch (error) {
            handleRequestError(error);
        }
    }


    async createAlias(aliasData: { // @ts-ignore
        id?: string, [x: string]: string; type: string; fieldName: string; alias: string;
        // @ts-ignore
        value?: string; exchangeMicCode?: string; assetClass?: string; }) {
        try {
            const response = await axios.post(`${ALIAS_SERVICE_URL}`, aliasData, {
                headers: {...authHeader(), ...ctype}
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async updateAlias(aliasData: { // @ts-ignore
        id?: string, [x: string]: string; type: string; fieldName: string; alias: string;
        // @ts-ignore
        value?: string; exchangeMicCode?: string; assetClass?: string; }) {
        try {
            const response = await axios.post(`${ALIAS_SERVICE_URL}/updateAlias`, aliasData, {
                headers: {...authHeader(), ...ctype}
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }


    async deleteAlias(type: string, id: string) {
        try {
            const response = await axios.delete(`${ALIAS_SERVICE_URL}`, {
                headers: { ...authHeader(), ...ctype },
                params: { type: type, uuid: id }
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

}

export default new AliasService();
