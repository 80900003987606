import {handleRequestError} from '../errorHandler';
import authHeader from './auth.header';
import axiosCache from '../components/cache/axiosCache'
import axios from "axios";

const ADMIN_SERVICE_URL = '/api/admin';
const ctype = {'Content-Type': 'application/json'}

class NotificationService {
    async getNotifications() {
        try {
            const response = await axios.get(`${ADMIN_SERVICE_URL}/notification`,
                {headers: {...authHeader(), ...ctype}});
            return response.data.result;
        } catch (error) {
            console.log(error)
            handleRequestError(error);
        }
    }

    async createNotification(notificationData: {
        client: string;
        clearingAccount: string;
        teamName: string;
        channelName: string;
    }) {
        try {
            const response = await axios.post(`${ADMIN_SERVICE_URL}/notification`, notificationData,
                {headers: {...authHeader(), ...ctype}});
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async updateNotification(notificationData: {
        id: string;
        client: string;
        clearingAccount: string;
        teamName: string;
        channelName: string;
    }) {
        try {
            const response = await axios.post(`${ADMIN_SERVICE_URL}/notification/update`, notificationData,
                {headers: {...authHeader(), ...ctype}});
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

    async deleteNotification(notificationId: string) {
        try {
            const response = await axios.delete(`${ADMIN_SERVICE_URL}/notification`, {
                headers: {...authHeader(), ...ctype},
                params: {notificationId}
            });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }
}

export default new NotificationService();
