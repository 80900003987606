import React, {useEffect, useState} from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    Divider,
    Menu,
    MenuItem,
    Tooltip,
    BoxProps,
    Avatar,
    CssBaseline,
    Box,
    Container,
} from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {useNavigate} from 'react-router-dom';
import useStyles from '../useStyles';
import {generalItems} from './menuData';
import {AdminItems} from './menuData';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import UserService from '../services/user.service';

interface LayoutProps extends BoxProps {
    children: React.ReactNode;
    dropdownItems: string[];
}

export function countryCodeToFlag(countryCode: string) {
    if (!countryCode || countryCode.length !== 2 || !/^[a-zA-Z]+$/.test(countryCode)) {
        return '🏳️'; // White Flag Emoji for unknown or invalid country codes
    }
    const code = countryCode.toUpperCase();
    const offset = 127397;
    return Array.from(code).map(letter => String.fromCodePoint(letter.charCodeAt(0) + offset)).join('');
}

function VOCSETLayout({children, dropdownItems}: LayoutProps) {
    const storedDrawerState = localStorage.getItem('drawerOpen');
    const [drawerOpen, setDrawerOpen] = useState(storedDrawerState ? JSON.parse(storedDrawerState) : true);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [privileges, setPrivileges] = useState<string[]>([]);
    const [userDetails, setUserDetails] = useState<any>(null);
    const [wasMinimised, setWasMinimised] = useState<any>(false);
    const navigate = useNavigate();
    const isMinimised = useMediaQuery('(max-width:900px)');
    const filteredDropdownItems = privileges.includes('ROLE_NONE')
        ? ['Log Out']
        : dropdownItems;

    useEffect(() => {
        const storedPrivileges = localStorage.getItem('privileges');
        if (storedPrivileges) {
            setPrivileges(JSON.parse(storedPrivileges));
        }

        const fetchUserDetails = async () => {
            try {
                const username = localStorage.getItem('currentUser');
                if (username) {
                    const userData = await UserService.getSingleUser(username);
                    setUserDetails(userData);
                }
            } catch (error) {
                console.error('Error fetching user details', error);
            }
        };

        fetchUserDetails();
    }, []);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (option: string) => {
        setAnchorElUser(null);
        if (option === 'Log Out') {
            handleLogout();
        } else if (option === 'Company Settings') {
            navigate('/CompanyDashboard');
        } else if (option === 'Edit Profile') {
            navigate('/EditProfile');
        } else if (option === 'API Management') {
            navigate('/APIDashboard');
        }
    };

    const handleLogout = async () => {
        try {
            localStorage.clear();
            navigate('/');
        } catch (error) {
            console.error('Error logging out', error);
        }
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
        localStorage.setItem('drawerOpen', String(!drawerOpen))
    };

    useEffect(() => {
        if (isMinimised) {
            if (drawerOpen == true) {
                toggleDrawer()
                setWasMinimised(true)
            }
        } else if (!isMinimised && wasMinimised) {
            setDrawerOpen(true)
            localStorage.setItem('drawerOpen', String(true))
        }
    }, [isMinimised]);


    const {classes} = useStyles();

    const drawer = (
        <div>
            <div className={classes.toolbar}/>
            <Divider/>
            <List>{generalItems({drawerOpen})}</List>
            <Divider/>
            <List>{AdminItems({drawerOpen})}</List>
        </div>
    );

    return (
        <ThemeProvider theme={createTheme()}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute" className={classes.appBar}>
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between', padding: '0 16px'}}>
                        {privileges.length > 0 && !privileges.includes('ROLE_NONE') && (
                            <IconButton
                                onClick={toggleDrawer}
                                sx={{
                                    color: 'white',
                                    display: {sm: 'block', xs: 'block'},
                                    mr: 2,
                                }}
                            >
                                <MenuOpenIcon/>
                            </IconButton>
                        )}
                        <div style={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                            <Avatar alt="VOCSET" src="/logo.png"/>
                            &nbsp;
                            <Tooltip title="Voice Order Confirmation System for Electronic Trading" arrow>
                                <Typography variant="h6" color="inherit" noWrap>
                                    VOCSET&nbsp;&nbsp;
                                </Typography>
                            </Tooltip>
                            {userDetails && userDetails.firstName && userDetails.lastName && userDetails.roles.length >
                                0 && userDetails.roles[0].name && userDetails.ownerName && (
                                    <Typography variant="body1">
                                        &nbsp; Welcome [{`${userDetails.firstName} ${userDetails.lastName}]`} @
                                    </Typography>
                                )}
                            {userDetails && userDetails.ownerName && (
                                <Typography variant="body1" sx={{display: 'flex'}}>
                                    {userDetails.ownerName}
                                </Typography>
                            )}
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <ManageAccountsIcon
                                        fontSize="large"
                                        sx={{
                                            color:
                                                window.location.hostname === 'dev.vocset.net' ? 'lightgreen' :
                                                    window.location.hostname === 'uat.vocset.net' ? 'white' :
                                                        window.location.hostname === 'app.vocset.net' ? 'orangered' :
                                                            'white'
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={() => handleCloseUserMenu('')}
                            >
                                {filteredDropdownItems.map((item) => (
                                    <MenuItem key={item} onClick={() => handleCloseUserMenu(item)}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                {privileges.length > 0 && !privileges.includes('ROLE_NONE') && (
                    <Drawer
                        variant="permanent"
                        className={`${classes.drawerPaper} ${!drawerOpen && classes.drawerPaperClose}`}
                        classes={{
                            paper: `${classes.drawerPaper} ${!drawerOpen && classes.drawerPaperClose}`,
                        }}
                        open={drawerOpen}
                    >
                        {drawer}
                    </Drawer>
                )}
                <Box
                    component="main"
                    style={{
                        display: 'flex',
                        flexFlow: 'column',
                        flex: 1,
                    }}
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth="xl" sx={{mt: 4, mb: 4}} style={{
                        display: 'flex',
                        flexFlow: 'column',
                        flex: 1
                    }}>
                        {children}
                    </Container>
                    <footer className="footer">
                        <Typography variant="body2">
                            {"v:"}{process.env.REACT_APP_VERSION}
                        </Typography>
                    </footer>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default VOCSETLayout;
