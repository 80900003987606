import React, {useState, useEffect} from 'react';
import {
    ButtonGroup, Dialog, DialogActions, DialogContent, Tab, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tabs,
    Typography
} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import SubAccountService from '../../services/subAccount.service';
import Table from '@mui/material/Table';
import {Link} from 'react-router-dom';
import Button from "@mui/material/Button";
import VOCSETLayout from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import {MoonLoader} from "react-spinners";
import {Account, Association, Company, SubAccount} from "../../types/APITypes";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import AccountService from "../../services/account.service";
import AssociationService from "../../services/association.service";
import ClearerAssociations from "../company/associations/ClearerAssocations";
import SubAccountAssociations from "./associations/SubAccountAssociations";
import subAccountAssociations from "./associations/SubAccountAssociations";


createTheme();
const SubAccountDashboard = () => {
    const [subAccounts, setSubAccounts] = useState<SubAccount[]>([]);
    const [selectedSubAccount, setSelectedSubAccount] = useState<SubAccount | null>(null);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [clearingAccounts, setClearingAccounts] = useState<Account[]>([]);
    const [subAccAssociations, setSubAccAssociations] = useState<Association[]>([]);
    const [allAssociations, setAllAssociations] = useState<Association[]>([]);
    const [selectedClient, setSelectedClient] = useState<string | null>(null);
    const [error, setError] = useState<string>('');
    const [open, setOpen] = useState(true);
    const [loading, setLoading] = useState(true);
    const [privileges, setPrivileges] = useState<string[]>([]);
    const [action, setAction] = useState<'activate' | 'delete' | 'error' | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAssociationDialog, setOpenAssociationDialog] = useState(false);
    const [openSubAccDialog, setOpenSubAccDialog] = useState(false);


    const resize = () => {
        setOpen(window.innerWidth >= 760);
    };

    useEffect(() => {
        // @ts-ignore
        window.addEventListener('resize', resize.bind(this));
        resize();
    }, []);

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                )
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                )
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        })
    }, [])

    useEffect(() => {
        const storedPrivileges = localStorage.getObj('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const [subAccountsResponse, accountsResponse] = await Promise.all([
                    SubAccountService.getSubAccounts(),
                    AccountService.getAccounts(),
                ]);

                if (subAccountsResponse && accountsResponse) {
                    setLoading(false);
                }

                // Handle sub-accounts
                const subAccounts = subAccountsResponse.result;
                if (subAccounts && subAccounts.length > 0) {
                    setSubAccounts(subAccounts);
                    const uniqueClients = Array.from(new Set(subAccounts.flatMap((acc: {
                        client: any
                    }) => acc.client)));
                    if (uniqueClients.length > 0) {
                        // @ts-ignore
                        setSelectedClient(uniqueClients[0]);
                    }
                } else {
                    setError('No sub-accounts available.');
                }

                const accounts = accountsResponse.result;
                if (accounts && accounts.length > 0) {
                    const filteredAccounts = accounts.filter(
                        (account: { role: { name: string }; active: boolean }) =>
                            account.role.name === "CLEARING_ACCOUNT" && account.active
                    );
                    if (filteredAccounts.length > 0) {
                        setClearingAccounts(filteredAccounts);
                    } else {
                        setError('No clearing accounts available.');
                    }
                } else {
                    setError('No accounts available.');
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching data:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching data. Please try again.');
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (privileges.includes('READ_ANY_ASSOCIATION') && clearingAccounts.length > 0) {
            const fetchData = async () => {
                await fetchAssociations();
            };

            fetchData().catch(error => console.error('Error fetching associations:', error));
        }
    }, [privileges, clearingAccounts]);


    const fetchAssociations = async () => {
        try {
            const fetchedData = await AssociationService.getAssociations();

            setSubAccAssociations(fetchedData.filter((asc: {
                associationTypeName: string
            }) => asc.associationTypeName === 'allocatesTo'));

        } catch (error) {
            console.error('Error fetching associations', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error fetching associations. Please try again.');
        }
    };


    const handleActivation = async (subAccount: SubAccount) => {
        try {
            const updatedSubAccountData = {
                ...subAccount,
                active: true,
            };

            await SubAccountService.updateSubAccount(updatedSubAccountData);

            setAction('activate');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);

        } catch (error: any) {
            console.error('Error activating sub account:', error);
            const errorMessage = error?.response?.data?.result?.[0]?.message || 'Error activating sub account. Please try again.';
            setError(errorMessage);
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };


    let dialogContent = '';
    if (action === 'activate') {
        dialogContent = 'Activation complete';
    } else if (action === 'delete') {
        dialogContent = 'Deactivation complete';
    } else if (action === 'error') {
        dialogContent = error;
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDelete = async (code: string, client: string) => {
        try {
            await SubAccountService.deleteSubAccount(code, client);
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error deleting sub account. Please try again.');
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    const uniqueClients = Array.from(
        new Set(subAccounts.flatMap(acc => acc.client))
    );

    const handleExternalCodeClick = (subAcc: SubAccount) => {
        setSelectedSubAccount(subAcc);
        setOpenAssociationDialog(true);
    };

    const handleOpenSubAccDialog = () => {
        setOpenAssociationDialog(false);
        setOpenSubAccDialog(true);
    };

    const handleCloseAssociationDialog = async () => {
        setOpenAssociationDialog(false);
        setSelectedSubAccount(null);
    };


    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                {(privileges.includes('CREATE_ANY_ACCOUNT') || privileges.includes('CREATE_OWN_ACCOUNT')) && (
                    <ButtonGroup style={{gap: '10px'}}>
                        <Button aria-label="New Sub Account" variant="contained" component={Link}
                                to="/CreateSubAccount" startIcon={<AddIcon/>}>New Sub Account</Button>
                    </ButtonGroup>
                )}
                <Tabs
                    value={selectedClient}
                    onChange={(_event, newValue) => setSelectedClient(newValue)}
                    aria-label="Client Tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {uniqueClients.map((client) => (
                        <Tab key={client} label={client} value={client}/>
                    ))}
                </Tabs>
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : (
                    subAccounts.length === 0 ? (
                        <p>No accounts found.</p>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>External Code</TableCell>
                                        {privileges.includes('WRITE_ANY_ACCOUNT') && (
                                            <TableCell>Actions</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subAccounts.map((subAcc) => (
                                        <TableRow key={subAcc.id}
                                                  style={{backgroundColor: subAcc.active ? '' : '#f5f5f5'}}>
                                            <TableCell>{subAcc.name}</TableCell>
                                            <TableCell>
                                                <Button onClick={() => handleExternalCodeClick(subAcc)}
                                                        variant="text" style={{textTransform: 'none'}}>
                                                    {subAcc.code}
                                                </Button>
                                            </TableCell>
                                            <TableCell>{subAcc.externalCode}</TableCell>
                                            {privileges.includes('WRITE_ANY_ACCOUNT') && (
                                                <TableCell>
                                                    <ButtonGroup className="dash-button-group">
                                                        <Link to={`/EditSubAccount`} state={subAcc}>
                                                            <Button className="dash-button" startIcon={<EditIcon/>}/>
                                                        </Link>
                                                        {subAcc.active ? (
                                                            <Button
                                                                className="dash-button"
                                                                startIcon={<DeleteIcon/>}
                                                                onClick={() => handleDelete(subAcc.code, subAcc.client)}
                                                            />
                                                        ) : (
                                                            <Button
                                                                className="dash-button"
                                                                startIcon={<DoneIcon/>}
                                                                onClick={() => handleActivation(subAcc)}
                                                            >
                                                            </Button>
                                                        )}
                                                    </ButtonGroup>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}

                <Dialog open={openAssociationDialog} onClose={handleCloseAssociationDialog}>
                    <DialogContent>
                        <Typography component="h1" variant="h5" sx={{alignSelf: 'flex-start'}}>
                            Manage Associations
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOpenSubAccDialog} variant="contained" color="primary">
                            Sub Accounts
                        </Button>
                        <Button onClick={handleCloseAssociationDialog} variant="contained" color="secondary"
                                sx={{bgcolor: "red", '&:hover': {bgcolor: "red"}}}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openSubAccDialog} onClose={() => setOpenSubAccDialog(false)}>
                    {openSubAccDialog && selectedSubAccount && clearingAccounts.length > 0 && (
                        <SubAccountAssociations
                            selectedSubAccount={selectedSubAccount}
                            associations={subAccAssociations}
                            clearingAccounts={clearingAccounts}
                            onClose={() => setOpenSubAccDialog(false)}
                            onAssociationUpdate={fetchAssociations}/>
                    )}
                </Dialog>

                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogContent>
                        <Typography variant="body1">{dialogContent}</Typography>
                    </DialogContent>
                </Dialog>

            </VOCSETLayout>
        </div>
    );
}

export default SubAccountDashboard;


