import React from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import TradeDashboard from '../components/trade/TradeDashboard';
import Login from "./Login";
import Register from './Register';
import Authenticate from './Authenticate';
import PasswordReset from './PasswordReset';
import PasswordConfirmation from './PasswordConfirmation';
import CompanyDashboard from "../components/company/CompanyDashboard";
import CreateCompany from "../components/company/CreateCompany";
import EditCompany from "../components/company/EditCompany";
import UserDashboard from "../components/user/UserDashboard";
import CreateUser from "../components/user/CreateUser";
import EditUser from "../components/user/EditUser";
import ExchangeDashboard from "../components/exchange/ExchangeDashboard";
import CreateExchange from "../components/exchange/CreateExchange";
import EditExchange from "../components/exchange/EditExchange";
import EditProfile from "../components/user/EditProfile";
import AccountDashboard from "../components/account/AccountDashboard";
import CreateExecAccount from "../components/account/CreateExecAccount";
import CreateClrAccount from "../components/account/CreateClrAccount";
import EditAccount from "../components/account/EditAccount";
import EditProduct from "../components/product/EditProduct";
import ProductDashboard from "../components/product/ProductDashboard";
import AliasDashboard from "../components/alias/AliasDashboard";
import CreateAlias from "../components/alias/CreateAlias";
import EditAlias from "../components/alias/EditAlias";
import APIDashboard from "../components/api/APIDashboard";
import CreateAPIKey from "../components/api/CreateAPIKey"
import AssociationDashboard from "../components/associations/AssociationDashboard"
import MetricsDashboard from "../components/metrics/MetricsDashboard";
import NotificationDashboard from "../components/notifications/NotificationDashboard";
import EditNotification from "../components/notifications/EditNotification";
import CreateNotification from "../components/notifications/CreateNotification";
import UserSetupNotification from "./UserSetupNotification";
import SubAccountDashboard from "../components/subAccount/SubAccountDashboard";
import CreateSubAccount from "../components/subAccount/CreateSubAccount";
import EditSubAccount from "../components/subAccount/EditSubAccount";

const Main = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code") || '';

    return (
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="/TradeDashboard" element={<TradeDashboard/>}/>
            <Route path="/UserSetupNotification" element={<UserSetupNotification/>}/>
            <Route path="/MetricsDashboard" element={<MetricsDashboard/>}/>
            <Route path="/Register" element={<Register/>}/>
            <Route path="/Authenticate" element={<Authenticate/>}/>
            <Route path="/PasswordReset" element={<PasswordReset/>}/>
            <Route
                path="/PasswordConfirmation"
                element={<PasswordConfirmation code={code}/>}
            />
            <Route path="/CompanyDashboard" element={<CompanyDashboard/>}/>
            <Route path="/CreateCompany" element={<CreateCompany/>}/>
            <Route path="/EditCompany" element={<EditCompany onSuccess={() => {
            }}/>}/>

            <Route path="/AccountDashboard" element={<AccountDashboard/>}/>
            <Route path="/CreateClrAccount" element={<CreateClrAccount/>}/>
            <Route path="/CreateExecAccount" element={<CreateExecAccount/>}/>
            <Route path="/EditAccount" element={<EditAccount onSuccess={() => {
            }}/>}/>

            <Route path="/SubAccountDashboard" element={<SubAccountDashboard/>}/>
            <Route path="/CreateSubAccount" element={<CreateSubAccount/>}/>
            <Route path="/EditSubAccount" element={<EditSubAccount onSuccess={() => {
            }}/>}/>

            <Route path="/UserDashboard" element={<UserDashboard/>}/>
            <Route path="/CreateUser" element={<CreateUser/>}/>
            <Route path="/EditUser" element={<EditUser onSuccess={() => {
            }}/>}/>
            <Route path="/EditProfile" element={<EditProfile onSuccess={() => {
            }}/>}/>

            <Route path="/APIDashboard" element={<APIDashboard onSuccess={() => {
            }}/>}/>
            <Route path="/CreateAPIKey" element={<CreateAPIKey onSuccess={() => {
            }}/>}/>

            <Route path="/ExchangeDashboard" element={<ExchangeDashboard/>}/>
            <Route path="/CreateExchange" element={<CreateExchange/>}/>
            <Route path="/EditExchange" element={<EditExchange onSuccess={() => {
            }}/>}/>

            <Route path="/ProductDashboard" element={<ProductDashboard/>}/>
            <Route path="/EditProduct" element={<EditProduct onSuccess={() => {
            }}/>}/>

            <Route path="/AssociationDashboard" element={<AssociationDashboard/>}/>

            <Route path="/AliasDashboard" element={<AliasDashboard/>}/>
            <Route path="/CreateAlias" element={<CreateAlias/>}/>
            <Route path="/EditAlias" element={<EditAlias onSuccess={() => {
            }}/>}/>
            <Route path="/NotificationDashboard" element={<NotificationDashboard/>}/>
            <Route path="/CreateNotification" element={<CreateNotification/>}/>
            <Route path="/EditNotification" element={<EditNotification onSuccess={() => {
            }}/>}/>

        </Routes>
    );
}

export default Main;
