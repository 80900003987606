import React, {useState, useEffect} from 'react';
import {Grid, Card, CardContent, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import VOCSETLayout from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import TradesChart from './components/TradesChart';
import Contracts from './components/Contracts';
import Trades from './components/Trades';
import tradeService from '../../services/trade.service';
import Brokers from "./components/Brokers";
import CompanyService from "../../services/company.service";
import {Company, TradeStatistics } from "../../types/APITypes";
import Compliance from "./components/Compliance";
import {calculateStats} from "./utils/calculateTradeData";
import BrokerPieChart from "./components/BrokerPieChart";

const MetricsDashboard = () => {
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(true);
    const [monthlyTradeStats, setMonthlyTradeStats] = useState<TradeStatistics[]>([]);
    const [weeklyTradeStats, setWeeklyTradeStats] = useState<TradeStatistics[]>([]);
    const [tradeStats, setTradeStats] = useState<TradeStatistics[]>([]);
    // const [tradeStats, setTradeStats] = useState([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchTrades();
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        setLoading(true);
        try {
            const response = await CompanyService.getCompanies();
            setCompanies(response.result);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setError('Error fetching companies. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const fetchTrades = async () => {
        try {
            const {result: fetchedTradeStats} = await tradeService.getTradeStatistics();
            setTradeStats(fetchedTradeStats || []);
            setMonthlyTradeStats(calculateStats(fetchedTradeStats, 30))
            setWeeklyTradeStats(calculateStats(fetchedTradeStats, 7))
        } catch (error) {
            console.error('Failed to fetch trades:', error);
            setError('Failed to load data');
        } finally {
            setLoading(false);
        }
    };

    return (
        <VOCSETLayout dropdownItems={dropdownItems}>
            <Grid container spacing={2} sx={{padding: 2}}>
                {[
                    <Trades trades={tradeStats}/>,
                    <Contracts trades={tradeStats}/>,
                    <Brokers trades={weeklyTradeStats} companies={companies}/>,
                    <Compliance trades={weeklyTradeStats}/>,
                ].map((Component, index) => (
                    <Grid key={index} item xs={12} sm={6} md={3}>
                        <Card sx={{height: 150}}>
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {Component}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}

                <Grid item xs={12} sm={6} md={6}>
                    <Card sx={{height: 500}}>
                        <CardContent>
                            <Typography variant="h6">Trades (30d)</Typography>
                            {monthlyTradeStats && monthlyTradeStats.length > 0 ? (
                                <TradesChart trades={monthlyTradeStats} />
                            ) : (
                                <Typography color="text.secondary">No trades available</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <Card sx={{height: 500}}>
                        <CardContent>
                            <Typography variant="h6">% Trades by Broker (30d)</Typography>
                            {monthlyTradeStats && monthlyTradeStats.length > 0 ? (
                                <BrokerPieChart trades={monthlyTradeStats} />
                            ) : (
                                <Typography color="text.secondary">No trades available</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </VOCSETLayout>
    );
};

export default MetricsDashboard;
