import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Select,
    Grid,
    Button,
    Typography,
    DialogActions,
    SelectChangeEvent
} from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {MoonLoader} from 'react-spinners';
import AssociationService from '../../../services/association.service';
import {Company, Association, APIResult} from '../../../types/APITypes';

interface ExecAssociationsProps {
    selectedCompany: Company;
    associations: Association[];
    companies: Company[];
    onClose: () => void;
    onAssociationUpdate: () => Promise<void>;
}

const ExecAssociations: React.FC<ExecAssociationsProps> = ({selectedCompany, associations, companies, onClose, onAssociationUpdate}) => {
    const [leftMenuOptions, setLeftMenuOptions] = useState<(Company | Association)[]>([]);
    const [rightMenuOptions, setRightMenuOptions] = useState<Association[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
    const [summaryData, setSummaryData] = useState<{ created: number; createErrors: number; deleted: number; deleteErrors: number } | null>(null);
    const [associationsToCreate, setAssociationsToCreate] = useState<Association[]>([]);
    const [associationsToDelete, setAssociationsToDelete] = useState<any[]>([]);

    useEffect(() => {
        if (selectedCompany) {
            const roleNames = ['EXECUTING_BROKER', 'FULL_SERVICE_BROKER'];
            const ownerExecAssociations = associations.filter(
                (association) =>
                    association.principalShortName === selectedCompany.shortName &&
                    companies
                        .find((companyItem) => companyItem.shortName === association.associateShortName)
                        ?.roles.some((role) => roleNames.includes(role.name))
            );

            const leftCompSelection = companies
                .filter(companyItem =>
                    companyItem.shortName !== selectedCompany.shortName &&
                    companyItem.roles.some(role => roleNames.includes(role.name)) &&
                    !ownerExecAssociations.some(association => association.associateShortName === companyItem.shortName)
                );

            setLeftMenuOptions(leftCompSelection);
            setRightMenuOptions(ownerExecAssociations);
            setLoading(false);
        }
    }, [selectedCompany, associations, companies]);

    const isCompany = (item: Company | Association): item is Company => {
        return (item as Company).shortName !== undefined;
    };

    const handleMoveRight = (event: SelectChangeEvent<string[]>) => {
        const value = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
        const newAssociations = value.map((val) => ({
            id: '',
            type: 'Company',
            principalShortName: selectedCompany ? selectedCompany.shortName : '',
            associateShortName: val as string,
            associationTypeName: 'executesWith',
        }));
        setRightMenuOptions(prev => [...prev, ...newAssociations]);
        setAssociationsToCreate(prev => [...prev, ...newAssociations]);
        setLeftMenuOptions(prev => prev.filter(item => {
            return isCompany(item) ? !value.includes(item.shortName) : true;
        }));
    };

    const handleMoveLeft = (item: Company | Association) => {
        if ('associationTypeName' in item) {
            setLeftMenuOptions(prev => [...prev, item]);
            setAssociationsToDelete(prev => [...prev, item]);
            setRightMenuOptions(prev => prev.filter(a => a.associateShortName !== item.associateShortName));
        } else {
            setRightMenuOptions(prev => prev.filter(a => a.associateShortName !== item.shortName));
            setLeftMenuOptions(prev => [...prev, item]);
            setAssociationsToDelete(prev => [...prev, item]);
        }
    };

    const handleSubmit = async () => {
        const execCreateAssociations = rightMenuOptions.filter(asc => !asc.id);
        const execDeleteAssociations = leftMenuOptions.filter(lft => !isCompany(lft) && lft.id);
        const idsToDelete = execDeleteAssociations.map(association => association.id);

        setAssociationsToCreate(execCreateAssociations);
        setAssociationsToDelete(execDeleteAssociations)

        try {
            let createResults: APIResult[] = [];
            if (execCreateAssociations.length) {
                const createResponse = await AssociationService.createAssociations(execCreateAssociations);
                createResults = createResponse?.result || [];
            }

            const associationsToDelete = idsToDelete.map(id => ({
                id,
                type: 'Company'
            }));

            let deleteResults: APIResult[] = [];
            if (associationsToDelete.length) {
                const deleteResponse = await AssociationService.deleteAssociations(associationsToDelete);
                deleteResults = deleteResponse?.result || [];
            }

            const createdCount = createResults.filter(({status}) => status === 'OK').length;
            const createErrors = new Set(createResults.filter(({status}) => status === 'ERROR').map(error => error.id)).size;

            const deletedCount = deleteResults.filter(({status}) => status === 'OK').length;
            const deleteErrors = new Set(deleteResults.filter(({status}) => status === 'ERROR').map(error => error.id)).size;

            setSummaryData({created: createdCount, createErrors, deleted: deletedCount, deleteErrors});
            setSummaryDialogOpen(true);

            await onAssociationUpdate();

        } catch (error) {
            console.error('Error creating associations:', error);
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error creating associations. Please try again.');
        }
    };

    const handleSummaryDialogClose = () => {
        setSummaryDialogOpen(false);
        onClose();
    };

    if (loading) return <MoonLoader color="#282c34"/>;

    return (
        <>
            <Dialog open={true} onClose={onClose}>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={5}>
                            <Select
                                multiple
                                native
                                value={[]}
                                onChange={handleMoveRight}
                                fullWidth
                                sx={{width: '120px'}}
                            >
                                {leftMenuOptions.map((item) => (
                                    isCompany(item) ? (
                                        <option key={item.shortName} value={item.shortName}>
                                            {item.shortName}
                                        </option>
                                    ) : (
                                        <option key={item.associateShortName} value={item.associateShortName}>
                                            {item.associateShortName}
                                        </option>
                                    )
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                            <CompareArrowsIcon/>
                        </Grid>
                        <Grid item xs={5}>
                            <Select
                                multiple
                                native
                                value={[]}
                                onChange={(event) => handleMoveLeft(rightMenuOptions.find(a => a.associateShortName === event.target.value)!)}
                                fullWidth
                                sx={{width: '120px'}}
                            >
                                {rightMenuOptions.map((association) => (
                                    <option key={association.associateShortName} value={association.associateShortName}>
                                        {association.associateShortName}
                                    </option>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    {error && <Typography color="error">{error}</Typography>}
                </DialogContent>
                <DialogActions sx={{justifyContent: 'flex-start', paddingLeft: '24px'}}>
                    <Button onClick={handleSubmit} variant="contained" color="primary"
                            disabled={associationsToCreate.length === 0 && associationsToDelete.length === 0}>
                        Submit
                    </Button>
                    <Button onClick={onClose} variant="contained" color="secondary" sx={{bgcolor: "red", '&:hover': {bgcolor: "red"}}}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={summaryDialogOpen} onClose={handleSummaryDialogClose}>
                <DialogTitle>Summary of Associations</DialogTitle>
                <DialogContent>
                    {summaryData && (
                        <>
                            {summaryData.created > 0 && (
                                <Typography>{summaryData.created} association(s) created successfully.</Typography>
                            )}
                            {summaryData.createErrors > 0 && (
                                <Typography>{summaryData.createErrors} association(s) failed to create.</Typography>
                            )}
                            {summaryData.deleted > 0 && (
                                <Typography>{summaryData.deleted} association(s) deleted successfully.</Typography>
                            )}
                            {summaryData.deleteErrors > 0 && (
                                <Typography>{summaryData.deleteErrors} association(s) failed to delete.</Typography>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSummaryDialogClose} variant="contained" color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ExecAssociations;
