import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CssBaseline,
    TextField,
    Typography,
    Dialog,
    DialogContent,
    Grid,
    MenuItem,
    Select
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import VOCSETLayout from '../../views/VOCSETLayout';
import NotificationService from '../../services/notification.service';
import { dropdownItems } from "../../views/dropdownItems";
import CompanyService from "../../services/company.service";
import { Account, Company } from "../../types/APITypes";
import AccountService from "../../services/account.service";

const CreateNotification = () => {
    const navigate = useNavigate();
    const [notificationState, setNotificationState] = useState({
        client: '',
        clearingAccount: '',
        teamName: '',
        channelName: ''
    });
    const [error, setError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [action, setAction] = useState<'save' | null>(null);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [accountsLoading, setAccountsLoading] = useState(false);

    useEffect(() => {
        async function fetchCompanies() {
            try {
                const response = await CompanyService.getCompanies();
                const fetchedCompanies = response.result;
                const clientCompanies = fetchedCompanies.filter((company: {
                        roles: any[];
                        active: boolean;
                    }) =>
                        company.roles.some(role => role.name === 'CLIENT') && company.active
                );
                if (clientCompanies && clientCompanies.length > 0) {
                    setCompanies(clientCompanies);
                } else {
                    setError('No companies available.');
                }
            } catch (error) {
                console.error('Error fetching companies:', error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching companies. Please try again.');
            }
        }

        fetchCompanies();
    }, []);

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                if (notificationState.client) {
                    setAccountsLoading(true);
                    const response = await AccountService.getAccounts();
                    const res = response.result;
                    if (res && res.length > 0) {
                        const filteredAccounts = res.filter((acc: { client: string; }) => acc.client === notificationState.client);
                        setAccounts(filteredAccounts);
                    } else {
                        setError('No accounts available.');
                    }
                }
            } catch (error) {
                // @ts-ignore
                console.error('Error fetching accounts:', error.response ? error.response.data : error);
                // @ts-ignore
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching accounts. Please try again.');
            } finally {
                setAccountsLoading(false);
            }
        };

        if (notificationState.client) {
            fetchAccounts();
        } else {
            setAccounts([]);
        }
    }, [notificationState.client]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/NotificationDashboard');
    };

    const handleSave = async () => {
        try {
            await NotificationService.createNotification(notificationState);
            setAction('save');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            console.error('Error creating notification:', error);
            setError('Error creating notification. Please try again.');
        }
    };

    let dialogContent = action === 'save' ? 'Notification created successfully!' : '';

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Typography component="h1" variant="h5" sx={{ alignSelf: 'flex-start' }}>
                        Create Notification
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2} sx={{ paddingBottom: 4 }}>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    required
                                    id="company"
                                    value={notificationState.client || ''}
                                    onChange={(e) => {
                                        setNotificationState({ ...notificationState, client: e.target.value });
                                    }}
                                    renderValue={(selected) => (selected === '' ? 'Select Client' : selected)}
                                    displayEmpty
                                    sx={{ marginTop: '16px' }}
                                >
                                    {companies.map((company: any) => (
                                        <MenuItem key={company.id} value={company.shortName}>
                                            {company.shortName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    required
                                    id="clearingAccount"
                                    value={notificationState.clearingAccount || ''}
                                    onChange={(e) => {
                                        setNotificationState({ ...notificationState, clearingAccount: e.target.value });
                                    }}
                                    renderValue={(selected) => (selected === '' ? 'Select Account' : selected)}
                                    displayEmpty
                                    sx={{ marginTop: '16px' }}
                                    disabled={!notificationState.client || accountsLoading}
                                >
                                    {accounts.map((acc, index) => (
                                        <MenuItem key={index} value={acc.name}>
                                            {acc.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="teamName"
                                    label="Team Name"
                                    value={notificationState.teamName}
                                    onChange={(e) =>
                                        setNotificationState({ ...notificationState, teamName: e.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="channelName"
                                    label="Channel Name"
                                    value={notificationState.channelName}
                                    onChange={(e) =>
                                        setNotificationState({ ...notificationState, channelName: e.target.value })
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={handleSave}
                                variant="contained"
                                sx={{ width: 'auto', mb: 2 }}
                                disabled={
                                    !notificationState.client ||
                                    !notificationState.clearingAccount ||
                                    !notificationState.teamName ||
                                    !notificationState.channelName
                                }
                            >
                                Save
                            </Button>
                            <Button
                                component={Link}
                                to="/NotificationDashboard"
                                variant="contained"
                                color="primary"
                                sx={{ width: 'auto', mb: 2, marginLeft: 3 }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        {error && (
                            <Typography variant="body2" color="error">
                                {error}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default CreateNotification;
