import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Select,
    Grid,
    Button,
    Typography,
    DialogActions,
    SelectChangeEvent
} from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {MoonLoader} from 'react-spinners';
import {Account, APIResult, Association, SubAccount} from '../../../types/APITypes';
import AssociationService from "../../../services/association.service";

interface SubAccountAssociationsProps {
    selectedSubAccount: SubAccount;
    associations: any[];
    clearingAccounts: Account[];
    onClose: () => void;
    onAssociationUpdate: () => Promise<void>;
}

const SubAccountAssociations: React.FC<SubAccountAssociationsProps> = ({
                                                                           selectedSubAccount,
                                                                           associations,
                                                                           clearingAccounts,
                                                                           onClose,
                                                                           onAssociationUpdate
                                                                       }) => {
    const [leftMenuOptions, setLeftMenuOptions] = useState<Account[]>([]);
    const [rightMenuOptions, setRightMenuOptions] = useState<Association[]>([]);
    const [existingAccounts, setExistingAccounts] = useState<Account[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const [removedAssociations, setRemovedAssociations] = useState<any[]>([]);
    const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
    const [summaryData, setSummaryData] = useState<{
        created: number;
        createErrors: number;
        deleted: number;
        deleteErrors: number
    } | null>(null);
    const [associationsToCreate, setAssociationsToCreate] = useState<Association[]>([]);
    const [associationsToDelete, setAssociationsToDelete] = useState<any[]>([]);

    useEffect(() => {
        if (selectedSubAccount) {
            const matchedAssociations = associations.filter(
                asc => asc.associateShortName === selectedSubAccount.code
            );

            const associatedClearingAccountNames = matchedAssociations.map(asc => asc.principalShortName);

            if (matchedAssociations.length > 0) {
                setRightMenuOptions(matchedAssociations);
            } else {
                setRightMenuOptions([]);
            }

            const leftMenu = clearingAccounts.filter(account =>
                !associatedClearingAccountNames.includes(account.name)
            );

            setLeftMenuOptions(leftMenu);
            setExistingAccounts(matchedAssociations);
            setLoading(false);
        }
    }, [selectedSubAccount, clearingAccounts, associations]);


    const handleMoveRight = (event: SelectChangeEvent<string[]>) => {
        const selectedIds = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
        const selectedAccounts = leftMenuOptions.filter(account => selectedIds.includes(account.id));
        const newAssociations: Association[] = selectedAccounts.map(account => ({
            id: '',
            type: 'Account',
            principalShortName: account.name,
            associateShortName: selectedSubAccount.code,
            associationTypeName: 'allocatesTo',
        }));

        setRightMenuOptions(prev => [...prev, ...newAssociations]);
        setLeftMenuOptions(prev => prev.filter(account => !selectedIds.includes(account.id)));
        setAssociationsToCreate(prev => [...prev, ...newAssociations]);
    };

    const handleMoveLeft = (event: SelectChangeEvent<string[]>) => {
        const selectedIds = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
        const selectedAssociations = rightMenuOptions.filter(asc => selectedIds.includes(asc.id));

        const idsToRemove = selectedAssociations.map(asc => asc.id);
        setRemovedAssociations(prev => [...prev, ...idsToRemove]);

        const accountsToMoveBack = selectedAssociations
            .map(asc => clearingAccounts.find(acc => acc.name === asc.principalShortName))
            .filter((acc): acc is Account => acc !== undefined);

        setLeftMenuOptions(prev => [...prev, ...accountsToMoveBack]);
        setRightMenuOptions(prev => prev.filter(asc => !selectedIds.includes(asc.id)));
        setAssociationsToDelete(prev => [...prev, ...selectedAssociations.map(asc => ({
            id: asc.id,
            type: 'Account'
        }))]);
    };


    const handleSubmit = async () => {
        const existingIds = existingAccounts.map(acc => acc.id);

        const associationsToCreate = rightMenuOptions
            .filter(val => !existingIds.includes(val.id))
            .map(val => ({
                id: '',
                type: 'Account',
                principalShortName: val.principalShortName,
                associateShortName: selectedSubAccount ? selectedSubAccount.code : '',
                associationTypeName: 'allocatesTo',
            }));

        const associationsToDelete = removedAssociations.map(id => ({
            id,
            type: 'Account'
        }));

        setAssociationsToCreate(associationsToCreate);
        setAssociationsToDelete(associationsToDelete);

        let createResults: APIResult[] = [];
        if (associationsToCreate.length > 0) {
            const createResponse = await AssociationService.createAssociations(associationsToCreate);
            createResults = createResponse?.result || [];
        }

        let deleteResults: APIResult[] = [];
        if (associationsToDelete.length > 0) {
            const deleteResponse = await AssociationService.deleteAssociations(associationsToDelete);
            deleteResults = deleteResponse?.result || [];
        }

        const createdCount = createResults.filter(({status}) => status === 'OK').length;
        const createErrors = new Set(createResults.filter(({status}) => status === 'ERROR').map(error => error.id)).size;

        const deletedCount = deleteResults.filter(({status}) => status === 'OK').length;
        const deleteErrors = new Set(deleteResults.filter(({status}) => status === 'ERROR').map(error => error.id)).size;

        setSummaryData({created: createdCount, createErrors, deleted: deletedCount, deleteErrors});
        setSummaryDialogOpen(true);

        await onAssociationUpdate();
    };

    const handleSummaryDialogClose = () => {
        onClose();
    };

    if (loading) return <MoonLoader color="#282c34"/>;

    return (
        <>
            <Dialog open={true} onClose={onClose}>
                <DialogTitle>Sub Account Associations</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={5}>
                            <Select
                                multiple
                                native
                                value={rightMenuOptions.map(item => item.id)}
                                onChange={handleMoveRight}
                                fullWidth
                                sx={{width: '120px'}}
                            >
                                {leftMenuOptions.map((account) => (
                                    <option key={account.id} value={account.id}>
                                        {account.name}
                                    </option>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                            <CompareArrowsIcon/>
                        </Grid>
                        <Grid item xs={5}>
                            <Select
                                multiple
                                native
                                value={[]}
                                onChange={handleMoveLeft}
                                fullWidth
                                sx={{width: '120px'}}
                            >
                                {rightMenuOptions.map((account) => (
                                    <option key={account.id} value={account.id}>
                                        {account.principalShortName}
                                    </option>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    {error && <Typography color="error">{error}</Typography>}
                </DialogContent>
                <DialogActions sx={{justifyContent: 'flex-start', paddingLeft: '24px'}}>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={associationsToCreate.length === 0 && associationsToDelete.length === 0}
                    >
                        Submit
                    </Button>
                    <Button onClick={onClose} variant="contained" color="secondary"
                            sx={{bgcolor: "red", '&:hover': {bgcolor: "red"}}}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={summaryDialogOpen} onClose={handleSummaryDialogClose}>
                <DialogTitle>Summary of Associations</DialogTitle>
                <DialogContent>
                    {summaryData && (
                        <>
                            {summaryData.created > 0 && (
                                <Typography>{summaryData.created} association(s) created successfully.</Typography>
                            )}
                            {summaryData.createErrors > 0 && (
                                <Typography>{summaryData.createErrors} association(s) failed to create.</Typography>
                            )}
                            {summaryData.deleted > 0 && (
                                <Typography>{summaryData.deleted} association(s) deleted successfully.</Typography>
                            )}
                            {summaryData.deleteErrors > 0 && (
                                <Typography>{summaryData.deleteErrors} association(s) failed to delete.</Typography>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSummaryDialogClose} variant="contained" color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SubAccountAssociations;