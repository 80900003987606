import axios from 'axios';
import {handleRequestError} from '../errorHandler';
import authHeader from "./auth.header";
import axiosCache from '../components/cache/axiosCache'

const SDM_SERVICE_URL = '/api/sdm';
const ctype = {'Content-Type': 'application/json'}

class RoleService {
    async getRolesByType(roleType: string) {
        try {
            const response = await axiosCache.get(`${SDM_SERVICE_URL}/roles`,
                {
                    headers: {...authHeader(), ...ctype},
                    params: {roleType: roleType},
                    id: 'roles'
                });
            return response.data;
        } catch (error) {
            handleRequestError(error);
        }
    }

}

export default new RoleService();
