import React, {useEffect, useState} from 'react';
import VOCSETLayout from './VOCSETLayout';
import {dropdownItems} from './dropdownItems';
import {Box, CssBaseline} from "@mui/material";

const UserSetupNotification = () => {
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const username = localStorage.getItem('currentUser');
        if (username) {
            setUserName(username);
        }
    }, []);

    return (
        <VOCSETLayout dropdownItems={dropdownItems}>
            <CssBaseline/>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div>
                    <p>
                        Your User Account <strong>{userName}</strong> is currently being set up by VOCSET support.<br/>
                        Please contact the helpdesk at <a href="mailto:support@vocset.net">support@vocset.net</a> for
                        more information.
                    </p>
                </div>
            </Box>
        </VOCSETLayout>
    );
};

export default UserSetupNotification;
