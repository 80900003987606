import React, {useState, useEffect} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ButtonGroup,
    Button, Dialog, DialogContent, Typography
} from '@mui/material';
import {MoonLoader} from 'react-spinners';
import VOCSETLayout from '../../views/VOCSETLayout';
import {dropdownItems} from '../../views/dropdownItems';
import RouteService from '../../services/notification.service';
import {Notification} from "../../types/APITypes";
import EditIcon from '@mui/icons-material/Edit';
import {Link} from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationService from "../../services/notification.service";

const NotificationDashboard = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [privileges, setPrivileges] = useState<string[]>([]);
    const [error, setError] = useState('');
    const [action, setAction] = useState<'delete' | 'error' | null>(null);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const storedPrivileges = localStorage.getObj('privileges');
        if (storedPrivileges) {
            setPrivileges(storedPrivileges);
        }
    }, []);

    useEffect(() => {
        RouteService.getNotifications()
            .then((fetchedData) => {
                if (!fetchedData) {
                    setError('No notifications available.');
                }
                setLoading(false)
                setNotifications(fetchedData);
            })
            .catch((error) => {
                // @ts-ignore
                console.error('Error fetching notifications:', error.response ? error.response.data : error);
                setError(error?.response?.data?.result?.[0]?.message || 'Error fetching notifications. Please try again.');
            });
    }, []);

    const handleDeleteClick = (event: { stopPropagation: () => void }, notificationId: string) => {
        event.stopPropagation();
        handleDelete(notificationId);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDelete = async (notificationId: string) => {
        try {
            await NotificationService.deleteNotification(notificationId);
            setAction('delete');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        } catch (error) {
            // @ts-ignore
            setError(error?.response?.data?.result?.[0]?.message || 'Error deleting notification. Please try again.');
            setAction('error');
            handleOpenDialog();
            setTimeout(() => {
                handleCloseDialog();
            }, 2000);
        }
    };

    let dialogContent = '';
    if (action === 'delete') {
        dialogContent = 'Deactivation complete';
    } else if (action === 'error') {
        dialogContent = error;
    }

    return (
        <div>
            <VOCSETLayout dropdownItems={dropdownItems}>
                {(privileges.includes('CREATE_ANY_NOTIFICATION') || privileges.includes('CREATE_OWN_NOTIFICATION')) && (
                    <ButtonGroup>
                        <Button aria-label="New Notification Button" variant="contained" component={Link}
                                to="/CreateNotification"
                                startIcon={<AddIcon/>}>Create Notification</Button>
                    </ButtonGroup>
                )}
                {loading ? (
                    <MoonLoader color="#282c34"/>
                ) : notifications.length === 0 ? (
                    <p>No notifications found.</p>
                ) : (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Client</TableCell>
                                    <TableCell>Clearing Account</TableCell>
                                    <TableCell>Team Name</TableCell>
                                    <TableCell>Channel Name</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {notifications.map((notification, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{notification.client}</TableCell>
                                        <TableCell>{notification.clearingAccount}</TableCell>
                                        <TableCell>{notification.teamName}</TableCell>
                                        <TableCell>{notification.channelName}</TableCell>
                                        {(privileges.includes('WRITE_ANY_NOTIFICATION') || privileges.includes('WRITE_OWN_NOTIFICATION')) && (
                                            <TableCell>
                                                <ButtonGroup className="dash-button-group">
                                                    <Link
                                                        to={`/EditNotification`} state={notification}
                                                        className="clt-row"
                                                        style={{textDecoration: 'none', color: 'inherit'}}
                                                    >
                                                        <Button className="dash-button" startIcon={<EditIcon/>}/>
                                                    </Link>
                                                    <Button
                                                        className="dash-button"
                                                        startIcon={<DeleteIcon/>}
                                                        onClick={(event) =>
                                                            handleDeleteClick(event, notification.id)}
                                                    />
                                                </ButtonGroup>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </VOCSETLayout>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogContent>
                    <Typography variant="body1">{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default NotificationDashboard;